
import axios from 'axios';
export default {
    
    async createClients(params)  {
        return await axios.post(`clients/create.php` , params)
    },
    async getAllClients()  {
        return await axios.get(`clients/readAll.php`)
    },
    async getOneClients(client_id)  {
        return await axios.get(`clients/readById.php?client_id=${client_id}`)
    },
    async getClientsByColumn(column , value)  {
        return await axios.get(`clients/readByColumn.php?column=${column}&value=${value}`)
    },
    async deleteClients(client_id)  {
        return await axios.post(`clients/delete.php?client_id=${client_id}`)
    },
    async updateClients(client_id , params)  {
        return await axios.post(`clients/update.php?client_id=${client_id}` , params)
    }
}