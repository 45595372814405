<template>
    <div>
        <v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
        <v-container v-else class="my-2">
            <v-layout row wrap px-0 mb-4>
                <v-flex xs9>
                    <v-breadcrumbs class="px-0" :items="$store.getters.language.data.clients.edit_path">
                        <template v-slot:item="{ item }">
                            <v-breadcrumbs-item :to="item.path" :disabled="item.disabled">
                                {{ item.title.toUpperCase() }}
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                </v-flex>
                <v-flex xs3>
                </v-flex>
            </v-layout>
            <form @submit.prevent="updateClients" autocomplete="off">
                <v-layout row wrap>

                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="clients.client_name_ku" type="text"
                            :label="$store.getters.language.data.clients.client_name_ku" class="mx-1" filled outlined
                            dense required>
                        </v-text-field>
                    </v-flex>
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="clients.client_name_en" type="text"
                            :label="$store.getters.language.data.clients.client_name_en" class="mx-1" filled outlined
                            dense required>
                        </v-text-field>
                    </v-flex>
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="clients.client_name_ar" type="text"
                            :label="$store.getters.language.data.clients.client_name_ar" class="mx-1" filled outlined
                            dense required>
                        </v-text-field>
                    </v-flex>
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <UploadComponent :options="{
                            table_name: 'clients',
                            column_name: 'client_logo',
                            table_key_name: 'client_id',
                            table_key_value: id,
                        }"
                        :fileName="clients.client_logo != '' || clients.client_logo != null ? clients.client_logo : null" />
                    </v-flex>
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="clients.client_index" type="number"
                            :label="$store.getters.language.data.clients.client_index" class="mx-1" filled outlined
                            dense required>
                        </v-text-field>
                    </v-flex>
                    <v-flex xs12 lg2 xl2 md2 sm4>
                        <v-btn color="success" type="submit">{{$store.getters.language.data.clients.update_btn}}</v-btn>
                    </v-flex>
                </v-layout>
            </form>
        </v-container>
        <v-snackbar v-model="snackbar.value" :color="snackbar.color">
            {{snackbar.text}}
        </v-snackbar>
    </div>
</template>
<script>
    import requests from './../../requests/clients.request.js'
    import UploadComponent from '../../components/UploadComponent.vue';
    export default {
        components: {
            UploadComponent
        },
        data() {
            return {
                clients: {},
                id: '',
                loading: true,
                snackbar: {
                    value: false,
                    text: '',
                    color: ''
                },

            }
        },
        computed: {

            page() {
                const pageKey = 'CLIENTS'
                return {}
            }
        },
        mounted() {
            this.id = this.$route.params.id
            this.getOneClients()
        },
        methods: {
            getOneClients() {
                this.loading = true
                requests.getOneClients(this.id).then(r => {
                        if (r.status == 200) {
                            this.clients = r.data.clients

                            this.loading = false
                        } else {
                            this.snackbar = {
                                value: true,
                                text: 'Fail to read Clients',
                                color: 'error'
                            }
                        }
                    })
                    .catch(e => {
                        this.snackbar = {
                            value: true,
                            text: 'Fail to read Clients',
                            color: 'error'
                        }
                    })
                    .finally(() => {
                        this.loading = false
                    })
            },
            updateClients() {
                this.loading = true
                requests.updateClients(this.id, this.clients).then(r => {
                        if (r.status == 200) {
                            this.snackbar = {
                                value: true,
                                text: 'Clients Updated',
                                color: 'success'
                            }
                            this.loading = false
                            this.getOneClients()
                        } else {
                            this.snackbar = {
                                value: true,
                                text: 'Update Faild',
                                color: 'error'
                            }
                            this.loading = false
                        }
                    })
                    .catch(e => {
                        this.snackbar = {
                            value: true,
                            text: 'Update Faild',
                            color: 'error'
                        }
                        this.loading = false
                    })
                    .finally(() => {
                        this.loading = false
                    })
            },
        },
    }
</script>